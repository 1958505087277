const Aboutme = () => (
  <div className="align-self-center aboutme-container me-lg-5">
    <h2>About me</h2>
    <p className="lead pt-4">
      Full-stack web developer and open-source
      enthusiast with a love for clean code,
      optimized solutions, and accessible design.
      Pair-programming and remote work aficionado.
      Fluent in multiple languages, frameworks, and technologies,
      and capable of ramping up quickly and efficiently.
      I am passionate about helping others and sharing my knowledge.
    </p>
    <a
      className="btn btn-primary mt-3"
      href="https://docs.google.com/document/d/e/2PACX-1vQa2q2dZIhcpw-obVNM-sKlyAhUjiBtPY27evjWxVusNbrBKugVYsHRcwItwOJql9jZv4sh3XgV9j73/pub"
    >
      Get my resume
    </a>
  </div>

);
export default Aboutme;
